import React from 'react'
import CopyButton from '../../../../../components/global/CopyButton'
import { useSelector } from 'react-redux'

export const SendConfirm = ({ formData, totalAmount }) => {
    const { balancesCrypto } = useSelector(
        (state) => state.juno
    )
    const imageBaseUrl = useSelector((state) => state.config.image_base_url)
    const crypto = balancesCrypto.find(
        (x) => x.currencyShortName === formData.cryptoId,
    )
    return (
        <>
            <p className="text-base font-normal text-[#51525C] ">
                Are you sure you want to send{' '}
                <span className="font-semibold text-[#18181B]">
                    {totalAmount} {formData.cryptoId}
                </span>{' '}
                to this address? This is a permanent action and cannot be undone
                once confirmed.
            </p>
            <p className="text-base font-normal text-[#51525C] mt-3">
                Please double check the address in full and confirm to continue.
            </p>
            <div className="border rounded-lg p-3 mt-12">
                <h1 className="text-base font-semibold">
                    Transaction information:
                </h1>
                <div className="mt-5">
                    <p className="text-sm font-normal text-[#51525C] ">Send:</p>
                    <div className="flex justify-between items-center">
                        <div className="flex items-center gap-2">
                            <img src={`${imageBaseUrl}${crypto?.currencyIcon}`} width={24} height={24} alt="img" className="" />
                            <span className="text-xl font-normal text-[#26272B]">
                                {crypto.blockchain}
                            </span>
                        </div>
                        <p className="text-xl font-semibold text-[#26272B] ">
                            {totalAmount} {formData.cryptoId}
                        </p>
                    </div>
                </div>
                <hr className="my-3" />
                <div className="flex justify-between items-center">
                    <p className="text-base font-normal text-[#51525C] ">
                        Name address:
                    </p>
                    <p className="text-base font-medium text-[#18181B] ">
                        {formData.selectedName}
                    </p>
                </div>
                <hr className="my-3" />
                <div className="">
                    <p className="text-base font-normal text-[#51525C] ">
                        Recipient address::
                    </p>
                    <p className="text-base font-medium text-[#18181B] flex justify-between items-center w-full">
                        {formData.toAddress}
                        <CopyButton copyValue={formData.toAddress} />
                    </p>
                </div>
            </div>
        </>
    )
}
