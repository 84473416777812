import React from 'react'
import JunoClientActionModal from '../table/JunoClientActionModal'
import JncButton from '../global/JncButton'
import CloseIcon from '@mui/icons-material/Close';

export const JncFailedModal = (props) => {
    const { open, onClose, error, title } = props

    const dialogContentComponent = () => {
        return (
            <>
                <div className="flex justify-center items-center flex-col">
                    <div className="rounded-full bg-[#FEF3F2] p-3">
                        <div className=" bg-[#FEE4E2] rounded-full p-3 ">
                            <div className="rounded-full flex items-center justify-center">
                                <CloseIcon style={{ width: "40px", height: "40px", color: "#D92D20", margin: 0 }} />
                            </div>
                        </div>
                    </div>
                    <p className="mt-8 text-[#26272B] text-xl font-semibold">{title}</p>
                    <p className="text-[#3F3F46] text-base font-normal">{error}</p>
                </div>
            </>
        )
    }

    const dialogActionsComponent = () => {
        return (
            <>
                <div className="jncModal__resp w-full" >
                    <JncButton
                        text="Retry"
                        onClickCall={onClose}
                        className={"w-full"}
                        isOutlinedStyle={true}
                        isDangerStyle={true}
                    />
                </div>
            </>
        )
    }
    return (
        <JunoClientActionModal
            dialogClassName={"jn-addressdetails-delete"}
            onClose={onClose}
            states={{
                start: true,
                confirm: false,
            }}
            open={open}
            dialogContent={dialogContentComponent()}
            dialogActions={dialogActionsComponent()}
        ></JunoClientActionModal>
    )
}
