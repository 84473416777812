import React from 'react'
import CopyButton from '../../components/global/CopyButton'
import { toFixedTrunc, getCurrencySymbol, getStatus } from '../../../helpers'
import { ErrorTransactions } from '../../constant'

export default function SellCryptoExpanded(data) {
    const details = data && data.transactionDetails[0]
    
    return (
        <div className="jncExpanded">
            <div className="jncExpanded-grid">
                {details.cryptoId && (
                    <div className="jncExpanded-block">
                        <p className="jncExpanded-label">CryptoId</p>
                        <div className="jncExpanded-block-copy jncExpanded-text">
                            <p className="is-ellipsis">{details.cryptoId}</p>
                            <CopyButton copyValue={details.cryptoId} />
                        </div>
                    </div>
                )}
                {details.cryptoAmount && (
                    <div className="jncExpanded-block">
                        <p className="jncExpanded-label">CryptoAmount</p>
                        <div className="jncExpanded-block-copy jncExpanded-text">
                            <p className="is-ellipsis">{details.cryptoAmount}</p>
                            <CopyButton copyValue={details.cryptoAmount} />
                        </div>
                    </div>
                )}
                {details.fiatCurrency && (
                    <div className="jncExpanded-block">
                        <p className="jncExpanded-label">FiatCurrency</p>
                        <div className="jncExpanded-block-copy jncExpanded-text">
                            <p className="is-ellipsis">{details.fiatCurrency}</p>
                            <CopyButton copyValue={details.fiatCurrency} />
                        </div>
                    </div>
                )}
                <div className="jncExpanded-block">
                    <p className="jncExpanded-label">FiatAmount</p>
                    <div className="jncExpanded-block-copy jncExpanded-text">
                        <p>
                            {getCurrencySymbol(details.fiatCurrency)}
                            {toFixedTrunc(details.fiatAmount, 2) || "-"}{' '}
                        </p>
                    </div>
                </div>
                <div className="jncExpanded-block">
                    <p className="jncExpanded-label">Transaction Fee</p>
                    <div className="jncExpanded-block-copy jncExpanded-text">
                        <p>{data.transactionFee || "-"}</p>
                        <CopyButton copyValue={data.transactionFee} />
                    </div>
                </div>
                <div className="jncExpanded-block">
                    <p className="jncExpanded-label">Total Amount</p>
                    <div className="jncExpanded-block-copy jncExpanded-text">
                        <p>{details.totalAmount || "-"}</p>
                        <CopyButton copyValue={details.totalAmount} />
                    </div>
                </div>
                <div className="jncExpanded-block">
                    <p className="jncExpanded-label">
                        Balance after transaction
                    </p>
                    {data.currencyType === 'crypto' && (
                        <div className="jncExpanded-block-copy jncExpanded-text">
                            <p>
                                {details.cryptoId}{' '}
                                {toFixedTrunc(
                                    data.balanceAfterTransaction,
                                    8,
                                )}
                            </p>
                            <CopyButton
                                copyValue={`${details.cryptoId} ${toFixedTrunc(data.balanceAfterTransaction, 8)}`}
                            />
                        </div>
                    )}
                </div>

                {ErrorTransactions.includes(data.status.code) && (
                    <div className="jncExpanded-block">
                        <p className="jncExpanded-failed">
                            {getStatus(data.status.code)}:
                        </p>
                        <div className="jncExpanded-block-copy jncExpanded-text">
                            <p>{data.status.message}</p>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}
