import React from 'react'
import InputCopyButton from '../../components/global/InputCopyButton'
import { toFixedTrunc, getCurrencySymbol, getCryptoStatus } from '../../../helpers'
import { ErrorTransactions } from '../../constant'

export default function CryptoSellTxnMobile({ data }) {
    const details = data && data.transactionDetails[0]
    const transactionFee = data && data.transactionFee

    return (
        <div>
            {data.fromAddress && (
                <div className="jncTransactionModal__group">
                    <p className="jncTransactionModal__group-text">
                        FromAddress:
                    </p>
                    <div className="jncTransactionModal__group-grid">
                        <p className="jncTransactionModal__group-text">
                            {data.fromAddress}
                        </p>
                        <InputCopyButton copyValue={data.fromAddress} />
                    </div>
                </div>
            )}
            {details.toAddress && (
                <div className="jncTransactionModal__group">
                    <p className="jncTransactionModal__group-text">ToAddress:</p>
                    <div className="jncTransactionModal__group-grid">
                        <p className="jncTransactionModal__group-text">
                            {details.toAddress}
                        </p>
                        <InputCopyButton copyValue={details.toAddress} />
                    </div>
                </div>
            )}
            {details.cryptoId && (
                <div className="jncTransactionModal__group">
                    <p className="jncTransactionModal__group-text">CryptoId:</p>
                    <div className="jncTransactionModal__group-grid">
                        <p className="jncTransactionModal__group-text">
                            {details.cryptoId}
                        </p>
                        <InputCopyButton copyValue={details.cryptoId} />
                    </div>
                </div>
            )}
            {details.cryptoAmount && (
                <div className="jncTransactionModal__group">
                    <p className="jncTransactionModal__group-text">CryptoAmount:</p>
                    <div className="jncTransactionModal__group-grid">
                        <p className="jncTransactionModal__group-text">
                            $ {toFixedTrunc(details.cryptoAmount, 2)}
                        </p>
                        <InputCopyButton
                            copyValue={`$ ${toFixedTrunc(
                                details.cryptoAmount,
                                2
                            )}`}
                        />
                    </div>
                </div>
            )}
            <div className="jncTransactionModal__group">
                <p className="jncTransactionModal__group-text">
                    Transaction Fee:
                </p>
                <div className="jncTransactionModal__group-grid">
                    <p className="jncTransactionModal__group-text">
                        {toFixedTrunc(transactionFee, 2) || '-'}
                    </p>
                    <InputCopyButton
                        copyValue={`$ ${toFixedTrunc(transactionFee, 2)}`}
                    />
                </div>
            </div>
            {data.balanceAfterTransaction && (
                <div className="jncTransactionModal__group">
                    <p className="jncTransactionModal__group-text">
                        Balance after transaction:
                    </p>
                    <div className="jncTransactionModal__group-grid">
                        <p className="jncTransactionModal__group-text">
                            {getCurrencySymbol(details.currency)}{' '}
                            {toFixedTrunc(data.balanceAfterTransaction, 2)}
                        </p>
                        <InputCopyButton
                            copyValue={`${getCurrencySymbol(
                                details.currency
                            )} ${toFixedTrunc(
                                data.balanceAfterTransaction,
                                2
                            )}`}
                        />
                    </div>
                </div>
            )}
            {ErrorTransactions.includes(data.status.code) && (
                <div className="jncTransactionModal__group">
                    <p className="jncTransactionModal__group-text">
                        {getCryptoStatus(data.status.code)}:
                    </p>
                    <div className="jncTransactionModal__group-grid">
                        <p className="jncTransactionModal__group-text">
                            {data.status.message}
                        </p>
                        <InputCopyButton copyValue={data.status.message} />
                    </div>
                </div>
            )}
        </div>
    )
}
