import React from 'react'
import CopyButton from '../../components/global/CopyButton'
import { toFixedTrunc, getCurrencySymbol, getStatus } from '../../../helpers'
import { ErrorTransactions } from '../../constant'

export default function ExchangeExpanded(data) {
    const details = data && data.transactionDetails[0]
    
    return (
        <div className="jncExpanded">
            <div className="jncExpanded-grid">
                {details.fromCryptoId && (
                    <div className="jncExpanded-block">
                        <p className="jncExpanded-label">FromCryptoId</p>
                        <div className="jncExpanded-block-copy jncExpanded-text">
                            <p className="is-ellipsis">{details.fromCryptoId}</p>
                            <CopyButton copyValue={details.fromCryptoId} />
                        </div>
                    </div>
                )}
                {details.fromCryptoAmount && (
                    <div className="jncExpanded-block">
                        <p className="jncExpanded-label">FromCryptoAmount</p>
                        <div className="jncExpanded-block-copy jncExpanded-text">
                            <p className="is-ellipsis">{details.fromCryptoAmount}</p>
                            <CopyButton copyValue={details.fromCryptoAmount} />
                        </div>
                    </div>
                )}
                {details.toCryptoId && (
                    <div className="jncExpanded-block">
                        <p className="jncExpanded-label">ToCryptoId</p>
                        <div className="jncExpanded-block-copy jncExpanded-text">
                            <p className="is-ellipsis">{details.toCryptoId}</p>
                            <CopyButton copyValue={details.toCryptoId} />
                        </div>
                    </div>
                )}
                <div className="jncExpanded-block">
                    <p className="jncExpanded-label">toCryptoAmount</p>
                    <div className="jncExpanded-block-copy jncExpanded-text">
                        <p>
                            {getCurrencySymbol(details.toCryptoId)}
                            {toFixedTrunc(details.toCryptoAmount, 2) || "-"}{' '}
                        </p>
                    </div>
                </div>
                <div className="jncExpanded-block">
                    <p className="jncExpanded-label">Transaction Fee</p>
                    <div className="jncExpanded-block-copy jncExpanded-text">
                        <p>{data.transactionFee || "-"}</p>
                        <CopyButton copyValue={data.transactionFee} />
                    </div>
                </div>
                <div className="jncExpanded-block">
                    <p className="jncExpanded-label">Total Amount</p>
                    <div className="jncExpanded-block-copy jncExpanded-text">
                        <p>{details.totalAmount || "-"}</p>
                        <CopyButton copyValue={details.totalAmount} />
                    </div>
                </div>
                <div className="jncExpanded-block">
                    <p className="jncExpanded-label">
                        Balance after transaction
                    </p>
                    {data.currencyType === 'crypto' && (
                        <div className="jncExpanded-block-copy jncExpanded-text">
                            <p>
                                {details.cryptoId}{' '}
                                {toFixedTrunc(
                                    data.balanceAfterTransaction,
                                    8,
                                )}
                            </p>
                            <CopyButton
                                copyValue={`${details.cryptoId} ${toFixedTrunc(data.balanceAfterTransaction, 8)}`}
                            />
                        </div>
                    )}
                </div>

                {ErrorTransactions.includes(data.status.code) && (
                    <div className="jncExpanded-block">
                        <p className="jncExpanded-failed">
                            {getStatus(data.status.code)}:
                        </p>
                        <div className="jncExpanded-block-copy jncExpanded-text">
                            <p>{data.status.message}</p>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}
